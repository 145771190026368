import React from 'react';

import Layout from './layout.js';
import DynamicLink from 'components/DynamicLink';

const About = () => (
  <Layout>
    <div className="about__wrapper">
      <div className="header__topper">
        <DynamicLink className="header__name" to="/">
          Caitlin Wang —{' '}
        </DynamicLink>
        <span className="header__description">About</span>
      </div>
      <div className="about__content">
        <p>
          Hi I’m Caitlin! I’m a design-minded engineer who cares about building
          software that is mindful and inclusive. I’m also interested in
          generative systems as they apply to art, design, and nature.
        </p>
        <p>
          I grew up in many places including Boston, Philadelphia, Shanghai, and
          of course, the Internet. But these days I live in Brooklyn and build
          websites and apps at{' '}
          <DynamicLink to="https://upstatement.com">Upstatement</DynamicLink>.{' '}
          In the last few years I’ve also worked on{' '}
          <DynamicLink to="https://poweradvocate.com">
            energy intelligence software
          </DynamicLink>
          , organized a{' '}
          <DynamicLink to="https://hackbeanpot.com">hackathon</DynamicLink>, and{' '}
          <DynamicLink to="https://www.khoury.northeastern.edu/">
            graduated
          </DynamicLink>{' '}
          with a degree in computer science.
        </p>
        <p>
          Thanks for dropping by and feel free to say hello via{' '}
          <DynamicLink to="mailto:caitlinwang97@gmail.com">email</DynamicLink>!
        </p>
      </div>
    </div>
  </Layout>
);

export default About;
